import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { RoundedImg } from '../RoundedImg/RoundedImg';
import { RoundedBox } from '../RoundedBox/RoundedBox';
import { Heading } from '../Headings';
import { P } from '../P';
import ServiceItem from './ServiceItem';
import { ShowModalButton } from '../Modal';

export const ServicesPage = ({ rightImage, leftImage }) => {
  return (
    <Flex
      justifyContent="center"
      width={[1, 1, 1440]}
      overflow="hidden"
      id="services"
    >
      {/* Inner content */}
      <Flex width={[1]} flexDirection="column">
        {/* Top section */}
        <Flex>
          {/* Center Column - All */}
          <Flex
            width={1}
            mt={[0, 0]}
            alignItems="center"
            flexDirection="column"
          >
            <Flex
              width={1}
              justifyContent="flex-start"
              mb={5}
              display={['flex', 'none']}
            >
              <RoundedBox height="250px" width={0.95}>
                <RoundedImg
                  height="250px"
                  fluid={leftImage.childImageSharp.fluid}
                  borderBottomRightRadius="25px"
                  borderTopRightRadius="25px"
                />
              </RoundedBox>
            </Flex>

            <Heading fontFamily="logo" color="darkGray" fontSize={12}>
              Services
            </Heading>

            {/* Text Content */}
            <Flex
              flexDirection="column"
              mt={4}
              px={[4]}
              width={[1, 1, 960]}
              alignItems="center"
            >
              <P mb="36px" textAlign="center">
                So what exactly do we do around here?
              </P>
              <P mb="36px" textAlign="center">
                With over 20 years of market & audience research combined, we
                possess a deep understanding of the modern consumer and how they
                make decisions.
              </P>
              <P mb="36px" textAlign="center">
                From market research and brand strategy to immersive &
                educational events, The Doja empowers consumers and amplifies
                their voice to create better products, messaging, and
                experiences.
              </P>
            </Flex>
            <Flex
              width={1}
              justifyContent="flex-end"
              mb={[0, 5]}
              display={['flex', 'none']}
            >
              <RoundedBox height="250px" width={0.95}>
                <RoundedImg
                  height="250px"
                  fluid={rightImage.childImageSharp.fluid}
                  borderBottomLeftRadius="25px"
                  borderTopLeftRadius="25px"
                />
              </RoundedBox>
            </Flex>
          </Flex>
        </Flex>

        {/* Lets work together */}
        <Flex px={3} mt="30px" justifyContent="center">
          <ShowModalButton>Let's work together!</ShowModalButton>
        </Flex>

        {/* Services */}
        <Flex mt="72px" flexDirection="column" mb="108px">
          <Flex
            alignItems={['center', 'flex-start']}
            justifyContent="center"
            flexDirection={['column', 'row']}
          >
            <ServiceItem
              icon="diamond"
              heading="Brand Management"
              headingColor="purple"
              subHeadings={[
                'Brand Positioning',
                'Brand Voice & Storytelling',
                'Competitive Analysis',
                'Thought Leadership',
              ]}
            />

            <ServiceItem
              icon="signs"
              heading="Strategic Planning"
              headingColor="blue"
              subHeadings={[
                'Strategic Roadmapping',
                'Internal Trainings',
                'Workshops & Offsites',
              ]}
            />

            <ServiceItem
              icon="barGraph"
              heading="Business Support"
              headingColor="brightGreen"
              subHeadings={[
                'Creative Problem Solving',
                'Data Mining & Synthesis',
                'Presentation Development',
                'Brainstorming Facilitation',
                'Internal Alignment',
              ]}
            />
          </Flex>

          <Flex
            justifyContent="center"
            alignItems={['center', 'flex-start']}
            flexDirection={['column', 'row']}
          >
            <ServiceItem
              icon="smile"
              heading="Audience Strategy"
              headingColor="magenta"
              subHeadings={[
                'Consumer Trends & Insights',
                'Marketplace Analysis',
                'Target Audience Research',
                'Product & UX Testing',
              ]}
            />

            <ServiceItem
              icon="lightBulb"
              heading="Marketing Activations"
              headingColor="brightOrange"
              subHeadings={[
                'Events & Experiences',
                'Education',
                'Community Building',
                'Panel Curation & Moderation',
                'Content Development',
              ]}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
