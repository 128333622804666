import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { Span } from '../../../../Span';
import PurpleHeading from '../../../../Headings/PurpleHeading/PurpleHeading';

export const HomePageBlock2 = () => {
  const { image } = useStaticQuery(graphql`
    query HomePageBlock2Query {
      image: file(relativePath: { eq: "cannabis-plant-blue-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1128, maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Flex
      flexDirection="column"
      width={1}
      alignItems="center"
      mt={[50, 50]}
      mb={3}
    >
      <Flex position="relative" pb={5}>
        <Flex
          width={[1]}
          height={[200]}
          pb={3}
          position="relative"
          justifyContent="center"
          alignItems="center"
        >
          <Box px={[3, 3, 5]} zIndex={1}>
            <PurpleHeading
              textAlign="center"
              fontFamily="logo"
              color="darkGray"
              fontSize={[8, 8, 11]}
            >
              We grow brands by putting people first (and not calling them
              consumers all the time)
            </PurpleHeading>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};
