import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { HomePageLeft } from './components/HomePageLeft';
import { HomePageRight } from './components/HomePageRight';

export const HomePageBlock1 = () => (
  <Flex width={1} flexDirection={['column', 'row', 'row']}>
    {/* <HomePageLeft /> */}
    <HomePageRight />
  </Flex>
);
