import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { RoundedImg } from '../../../../RoundedImg';
import { PurpleHeading } from '../../../../Headings/PurpleHeading/PurpleHeading';
import { ShowModalButton } from '../../../../Modal/ShowModalButton';
import { Stack } from '@chakra-ui/react';

export const HomePageBlock5 = () => {
  return (
    <Flex
      width={1}
      flexDirection={['column-reverse', 'row', 'row']}
      justifyContent={['center']}
      mt={72}
      pb={100}
      position="relative"
    >
      <Stack justifyContent={['center']} alignItems={['center']}>
        <PurpleHeading
          fontSize={10}
          fontFamily="logo"
          textAlign="center"
          color="mustard"
          width={[1, 1, '552px']}
          my={3}
        >
          Want to know more?
        </PurpleHeading>

        <ShowModalButton width={200} height={48}>
          LET'S TALK
        </ShowModalButton>
      </Stack>
    </Flex>
  );
};
