import * as React from 'react';
import { BodyContainer } from '../../BodyContainer';
import { HomePageBlock1 } from './components/HomePageBlock1';
import { HomePageBlock2 } from './components/HomePageBlock2';
import { HomePageBlock3 } from './components/HomePageBlock3';
import { HomePageBlock4 } from './components/HomePageBlock4';
import { HomePageBlock5 } from './components/HomePageBlock5';
import { WhatDoWomenWantSection } from './components/WhatDoWomenWantSection';
import Page from '../../Page/Page';
import { BridgeThatGapBlock } from './components/BridgeThatGapBlock';
import { ServicesPage } from '../../ServicesPage/ServicesPage';
import { Clients } from './components/Clients';
import { WhatSetsUsApart } from './components/WhatSetsUsApart';

export const HomePage = ({ leftImage, rightImage }) => (
  <Page>
    <BodyContainer flexDirection="column">
      <HomePageBlock1 />
      <HomePageBlock2 />
      <ServicesPage leftImage={leftImage} rightImage={rightImage} />
      <Clients />
      <WhatSetsUsApart />
      {/* <HomePageBlock3 /> */}
      {/* <BridgeThatGapBlock /> */}
      {/* <HomePageBlock4 /> */}
      {/* <WhatDoWomenWantSection /> */}
      <HomePageBlock5 />
    </BodyContainer>
  </Page>
);
