import { Box, Flex } from '@chakra-ui/react';
import Page from '../../../Page/Page';
import * as React from 'react';
import { Heading } from '../../../Headings/Heading/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { RoundedImg } from '../../../RoundedImg/RoundedImg';

export const Clients = () => {
  const {
    desktop: {
      childImageSharp: { fluid: desktop },
    },
    mobile: {
      childImageSharp: { fluid: mobile },
    },
  } = useStaticQuery(graphql`
    query ClientsQuery {
      desktop: file(relativePath: { eq: "doja-clients-desktop.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "doja-clients-mobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Page minHeight="auto" pb={100}>
      <Flex justifyContent="center" width={[1]} maxWidth={1440}>
        <Flex width={[1]} flexDirection="column">
          {/* Center Column - All */}
          <Flex
            width={1}
            mt={[5, 150, 148]}
            alignItems="center"
            flexDirection="column"
          >
            <Heading
              fontFamily="logo"
              fontSize={12}
              color="darkGray"
              mb={[40]}
              textAlign="center"
            >
              Client Highlights
            </Heading>
            <Box px={4} width={['100%']}>
              <Box width={['100%']} display={['none', 'none', 'block']}>
                <RoundedImg fluid={desktop} />
              </Box>
              <Box width={['100%']} display={['block', 'block', 'none']}>
                <RoundedImg fluid={mobile} />
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
};
