import * as React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { HomePage } from '../components/Pages/HomePage';

import Layout from '../components/Layout/Layout';

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
    rightImage: any;
    leftImage: any;
  };
}

const RootIndex = (props: Props) => {
  const {
    data: {
      site: {
        siteMetadata: { title: siteTitle },
      },
      rightImage,
      leftImage,
    },
  } = props;

  return (
    <Layout>
      <Helmet title={siteTitle} />
      <HomePage leftImage={leftImage} rightImage={rightImage} />
    </Layout>
  );
};

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    leftImage: file(relativePath: { eq: "cheers-white-stripes.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 324, maxHeight: 576) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rightImage: file(relativePath: { eq: "rolling-j-turquoise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 324, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
