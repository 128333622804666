import React from 'react';
import { Heading } from '../Headings';
import { Text } from '@westhechiang/text';
import { Flex } from '@westhechiang/flex';
import { Icon } from '../Icon';

interface ServiceItemProps {
  icon: string;
  heading: string;
  headingColor: string;
  subHeadings: string[];
}

export const ServiceItem = ({
  icon,
  heading,
  headingColor,
  subHeadings,
}: ServiceItemProps) => (
  <Flex maxWidth="360px" flexDirection="column" alignItems="center" m={3}>
    <Icon height="48px" width="48px" mb="23px" icon={icon} />

    <Heading
      mb="15px"
      fontSize={6}
      color={headingColor}
      fontFamily="logo"
      textAlign="center"
    >
      {heading}
    </Heading>

    {subHeadings.map((subHeading) => (
      <Text
        m="0"
        mb="17px"
        fontSize="16px"
        textAlign="center"
        key={subHeading}
        fontFamily="body"
        color="darkGray"
      >
        {subHeading}
      </Text>
    ))}
  </Flex>
);

export default ServiceItem;
