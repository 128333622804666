import barGraph from './assets/bar-chart.svg';
import diamond from './assets/diamond.svg';
import lightBulb from './assets/bulb.svg';
import signs from './assets/directions.svg';
import smile from './assets/emoticon.svg';

export const icons = {
  barGraph,
  diamond,
  lightBulb,
  signs,
  smile,
};
