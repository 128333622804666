import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { RoundedImg } from '../../../../../../RoundedImg';
import { HomePageCTA } from '../HomePageLeft/components/HomePageCTA';
import { PurpleHeading } from '../../../../../../Headings/PurpleHeading/PurpleHeading';
import { PinkHeading } from '../../../../../../Headings/PinkHeading/PinkHeading';
import { ShowModalButton } from '../../../../../../Modal/ShowModalButton';

export const HomePageRight = () => {
  const { image } = useStaticQuery(graphql`
    query HomePageRightQuery {
      image: file(relativePath: { eq: "purple-people.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
      <Box display={['block']}>
        <HomePageCTA />
      </Box>
      <Flex width={1}>
        <Box
          width={[1, 1, 708]}
          height={['300', 'auto', 'auto']}
          display={['none', 'none', 'block']}
          position="relative"
          paddingTop={90}
        >
          <RoundedImg
            borderBottomLeftRadius={['0px', '0px', '25px']}
            fluid={image.childImageSharp.fluid}
          />
        </Box>
      </Flex>
    </Flex>
  );
};
