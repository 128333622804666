import { Flex, Stack } from '@chakra-ui/react';
import { P } from '../../../P/P';
import * as React from 'react';
import { Heading } from '../../../Headings/Heading/Heading';

const data = [
  {
    heading: 'Learning',
    body:
      'At the doja, we stay in a place of learning, responsive to the ever-evolving landscape',
  },
  {
    heading: 'Curious',
    body:
      'We tap into our community of naturally curious and analytical minds through deep connections across many verticals and industries',
  },
  {
    heading: 'Human-centric',
    body:
      'Spotlighting whitespaces & underserved groups, balancing scale with personalization',
  },
];

export const WhatSetsUsApart = () => {
  return (
    <Stack spacing={20} py={80}>
      <Heading
        fontFamily="logo"
        color="mustard"
        fontSize={12}
        textAlign="center"
      >
        What Sets Us Apart?
      </Heading>
      <Stack px={4}>
        {data.map(({ heading, body }, index) => (
          <Flex
            key={heading}
            alignItems={['center']}
            justifyContent="space-between"
            borderBottom={data.length - 1 !== index ? '1px solid gray' : 'none'}
            borderColor="mustard"
            flexDirection={['column', 'row']}
            py={5}
          >
            <Heading fontFamily="logo" color="darkGray" fontSize={[8, 10]}>
              {heading}
            </Heading>
            <P
              maxWidth={['100%', 500]}
              pl={[0, 40]}
              pr={[0, 0]}
              mb={0}
              textAlign={['center', 'left']}
            >
              {body}
            </P>
          </Flex>
        ))}
      </Stack>
    </Stack>
  );
};
